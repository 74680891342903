<!--
 打印设计
-->
<template>
  <div
    style="width: 100%; padding: 20px"
    width="100%"
    :visible="visible"
    destroyOnClose
    :closable="false"
    @close="visible = false"
    v-loading="divloading"
  >
    <a-row style="margin-bottom: 10px">
      <div style="display: inline-block; margin-bottom: 10px">
        纸张大小:&nbsp;
        <a-radio-group
          style="margin-left: 10px"
          v-model="keyType"
          button-style="solid"
          @change="changekeytype"
        >
          <a-tooltip
            placement="bottom"
            v-for="item in paperTypes"
            :key="item.key"
          >
            <template slot="title">
              <span>宽：{{ item.width }}cm</span>
              <span>&nbsp;--&nbsp;</span>
              <span>高：{{ item.height }}cm</span>
            </template>
            <a-popconfirm
              title="切换纸张大小会重置模板内容，确定切换吗?"
              ok-text="确定"
              cancel-text="取消"
              @confirm="confirmkeyType(item.key)"
              :disabled="keyType === item.key"
            >
              <a-radio-button :value="item.key">
                {{ item.key }}
              </a-radio-button>
            </a-popconfirm>
          </a-tooltip>
          <a-popover
            title="自定义宽(cm)高(cm)"
            trigger="click"
            v-model="paperPopVisible"
          >
            <template slot="content">
              <div>
                宽：<a-input-number
                  v-model="Customize.width"
                  :min="5"
                  style="width: 70px"
                />&nbsp; 高：<a-input-number
                  v-model="Customize.height"
                  :min="5"
                  style="width: 70px"
                />
              </div>

              <div style="text-align: center; margin-top: 10px">
                <a-popconfirm
                  title="切换纸张大小会重置模板内容，确定切换吗?"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="Customizeyes"
                >
                  <a-button type="param">确定</a-button>
                </a-popconfirm>
              </div>
            </template>
            <a-radio-button :key="'自定义'" :value="'自定义'">
              自定义
            </a-radio-button>
          </a-popover>
        </a-radio-group>
      </div>
      <a-button style="margin-left: 10px" type="primary" v-print="'#print'"
        >打印</a-button
      >
      <a-popconfirm
        style="margin-left: 10px"
        title="是否确认清空?"
        cancelText="取消"
        okText="确定清空"
        okType="danger"
        @confirm="
          printlist = [];
          form = {};
        "
      >
        <a-icon slot="icon" type="question-circle-o" style="color: red" />
        <a-button type="danger">
          清空
          <a-icon type="close" />
        </a-button>
      </a-popconfirm>
      <div
        style="display: inline-block; margin-left: 10px; margin-bottom: 10px"
      >
        选择公司：&nbsp;
        <CompanySearchSelect
          style="display: inline-block"
          v-model="CompanyId"
          @select="changeSearchSelect"
        ></CompanySearchSelect>
      </div>
      <a-popconfirm
        style="margin-left: 10px; margin-bottom: 10px"
        title="是否确认保存模板?"
        cancelText="取消"
        okText="确定"
        @confirm="subbaocun"
      >
        <a-button style="margin-left: 10px; margin-bottom: 10px" type="primary"
          >保存模板</a-button
        >
      </a-popconfirm>
    </a-row>
    <a-row :gutter="[8, 0]">
      <a-col :span="4">
        <a-card>
          <a-row
            v-for="item in list"
            :key="item.Keys"
            style="margin-bottom: 15px"
          >
            <a-button style="width: 100%" @click="addprint(item)">{{
              item.Key
            }}</a-button>
          </a-row>
        </a-card>
      </a-col>
      <a-col
        :span="15"
        :style="`overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;height: 100vh;`"
        @click.self="divcalck"
      >
        <div
          :style="`width: ${styleW}cm;height: ${styleH}cm;border:1px solid #ccc;margin:auto`"
        >
          <div
            style="width: 100%; height: 100%; position: relative"
            id="print"
            @click.self="divcalck"
          >
            <vue-drag-resize
              v-for="(item, index) in printlist"
              :key="item.Id"
              snapToGrid
              :stickSize="12"
              :gridX="2"
              :gridY="2"
              :isActive="item.isActive"
              :preventActiveBehavior="item.isActive"
              :isDraggable="item.isDraggable"
              :isResizable="item.isResizable"
              :aspectRatio="item.Keys === 'qrcode' ? true : false"
              parentLimitation
              :parentW="getpx(styleW, 'w', 'px')"
              :parentH="
                getpx(
                  styleH * (Number(printPages) > 1 ? Number(printPages) : 1),
                  'h',
                  'px'
                )
              "
              :w="item.w"
              :h="item.h"
              :x="item.x"
              :y="item.y"
              :minh="28"
              :minw="80"
              @clicked="clicked(item)"
              @resizing="resizing($event, item)"
              @resizestop="resizestop($event, item)"
              @dragging="dragging($event, item)"
              @dragstop="dragstop($event, item)"
              @activated="activated(item, index)"
            >
              <div
                v-if="item.type === '1' || item.type == '12'"
                :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;text-align:${item.textAlign}`"
              >
                <p>{{ item.value || item.Key }}</p>
              </div>
              <div
                v-if="item.type === '3'"
                :style="`border:1px solid #ccc;border-radius:50%;text-align:center;width: ${item.w};height: ${item.h};line-height: ${item.h}px;flex-wrap: nowrap;`"
              >
                {{ item.Key }}
              </div>
              <div
                v-if="item.type === '4'"
                :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;text-align:${item.textAlign}`"
              >
                <table border="1" style="width: 100%; height: auto">
                  <thead
                    :style="`background-color: #ccc; box-sizing: border-box;font-family:${item.family} `"
                  >
                    <td
                      v-for="(val, index) in item.columns"
                      :key="val.dataIndex"
                      :style="`width: ${val.width || 50}px;line-height: ${
                        val.lineHeight || 20
                      }px;  text-align: ${val.align || 'center'};font-weight:${
                        val.bold || 'initial'
                      };font-size:${val.fontSize || 18}px`"
                    >
                      {{ val.title }}
                    </td>
                  </thead>

                  <tr v-for="(itemdata, index) in item.datalist" :key="index">
                    <td
                      v-for="(val, index) in item.columns"
                      :key="index"
                      :style="`text-align: ${val.textalign || 'center'};`"
                    >
                      <div
                        :style="`text-align: ${
                          val.textalign || 'center'
                        };height:${
                          val.textlineHeight || 20
                        }px;overflow: hidden;`"
                      >
                        {{ itemdata[val.dataIndex] || "&nbsp;" }}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </vue-drag-resize>
          </div>
        </div>
      </a-col>
      <a-col :span="5">
        <a-card>
          <a-form-model :model="form" v-if="form.Id">
            <div v-if="form.type === '1' || form.type == '12'">
              <a-row>
                <a-form-model-item :label="form.Key">
                  <a-input v-model="form.value" type="textarea" />
                  <div
                    v-if="form.type == '12'"
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      color: red;
                    "
                  >
                    '{}'为录入项占位符
                    <a-button @click="textPushInput" type="primary"
                      >添加录入项
                    </a-button>
                  </div>
                </a-form-model-item>
              </a-row>
              <a-row :gutter="15">
                <a-col :span="12">
                  <a-form-model-item label="字体大小(px)">
                    <a-select v-model="form.fontSize" size="small">
                      <a-select-option
                        :value="item"
                        v-for="(item, index) in fontSizeList"
                        :key="index"
                      >
                        {{ item }}(px)
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="粗体">
                    <a-select v-model="form.bold" size="small">
                      <a-select-option
                        :value="item.value"
                        v-for="(item, index) in boldList"
                        :key="index"
                      >
                        {{ item.key }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="15">
                <a-col :span="12">
                  <a-form-model-item label="字体行高(px)">
                    <a-select v-model="form.lineHeight" size="small">
                      <a-select-option
                        :value="item"
                        v-for="(item, index) in lineHeightList"
                        :key="index"
                      >
                        {{ item }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="字体对齐">
                    <a-select v-model="form.textAlign" size="small">
                      <a-select-option
                        :value="item.value"
                        v-for="(item, index) in alignList"
                        :key="index"
                      >
                        {{ item.key }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :getter="15">
                <a-col :span="12">
                  <a-form-model-item label="索引排序">
                    <a-input-number :min="0" v-model="form.sort" size="small">
                    </a-input-number>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>
            <a-row>
              <div
                style="
                  width: 100%;
                  display: flex;
                  justify-content: space-around;
                  margin-top: 50px;
                "
              >
                <a-button @click="del(form)" type="danger">删除</a-button>
                <a-button @click="formyes(form)" type="primary">确定</a-button>
              </div>
            </a-row>
          </a-form-model>
        </a-card>
      </a-col>
    </a-row>

    <div v-html="styleText"></div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import VueBarcode from "vue-barcode";
function jsgetDPI() {
  var arrDPI = new Array();
  if (window.screen.deviceXDPI != undefined) {
    arrDPI[0] = window.screen.deviceXDPI;
    arrDPI[1] = window.screen.deviceYDPI;
  } else {
    var tmpNode = document.createElement("DIV");
    tmpNode.style.cssText =
      "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
    document.body.appendChild(tmpNode);
    arrDPI[0] = parseInt(tmpNode.offsetWidth);
    arrDPI[1] = parseInt(tmpNode.offsetHeight);
    tmpNode.parentNode.removeChild(tmpNode);
  }
  return arrDPI;
}
import CompanySearchSelect from "@/components/CompanySearchSelect.vue";
export default {
  data() {
    return {
      divloading: false,
      CompanyId: "",

      fontSizeList: [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34],
      boldList: [
        { key: "默认", value: "initial" },
        { key: "加粗", value: "bold" },
        { key: "100", value: "100" },
        { key: "200", value: "200" },
        { key: "300", value: "300" },
        { key: "400", value: "400" },
        { key: "500", value: "500" },
        { key: "600", value: "600" },
        { key: "700", value: "700" },
        { key: "800", value: "800" },
        { key: "900", value: "900" },
      ],
      alignList: [
        { key: "左对齐", value: "left" },
        { key: "居中", value: "center" },
        { key: "右对齐", value: "right" },
      ],
      lineHeightList: [26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46],
      subform: {
        Type: 0,
        TemName: "",
        Amodel: "",
      },
      modalflag: false,
      loading: false,
      form: {},
      printlist: [],
      keyType: "A4",
      printPages: 1,
      printsize: "portrait",
      Customize: {
        key: "自定义",
        width: 21,
        height: 29,
      },

      paperTypes: [
        {
          key: "A4",
          width: 21,
          height: 29,
        },
        {
          key: "A5",
          width: 21,
          height: 14.5,
        },
      ],

      visible: true,

      list: [
        {
          Key: "标题",
          Keys: "Title",
          value: "",
          Id: "Title",
          type: "1",
          w: 260,
          h: 35,
        },
        {
          Key: "段落",
          Keys: "text",
          value: "段落",
          Id: "text",
          type: "12",
          w: 260,
          h: 35,
        },
        {
          Key: "客户名称",
          Keys: "CustomerName",
          value: "",
          Id: "CustomerName",
          type: "1",
          w: 250,
          h: 35,
        },
        {
          Key: "日期",
          Keys: "Time",
          value: "",
          Id: "Time",
          type: "1",
          w: 250,
          h: 35,
        },
        {
          Key: "账期区间",
          Keys: "Range",
          value: "",
          Id: "Range",
          type: "1",
          w: 350,
          h: 35,
        },
        {
          Key: "客户签章",
          Keys: "cusSeal",
          value: "",
          Id: "cusSeal",
          type: "3",
          w: 200,
          h: 200,
        },
        {
          Key: "公司签章",
          Keys: "comSeal",
          value: "",
          Id: "comSeal",
          type: "3",
          w: 200,
          h: 200,
        },
        {
          Key: "往来明细",
          Keys: "table",
          value: "",
          Id: "table",
          type: "4",
          w: 200,
          h: 200,
        },
      ],
      tableCodeData: [
        { code: "商品码", sort: "", index: 1, Id: "1" },
        // { code: '厂商码', sort: '', index: 2, Id: '2' },
        { code: "批次码", sort: "", index: 2, Id: "2" },
        { code: "序号码", sort: "", index: 3, Id: "3" },
        { code: "国码", sort: "", index: 4, Id: "4" },
        { code: "客户商品码", sort: "", index: 5, Id: "5" },
      ],
      template: [],
      typeitem: {
        isActive: false, //激活状态
        isDraggable: true,
        isResizable: true,
        aspectRatio: true, //允许等比例缩放
        parentLimitation: true, //不允许超出父级元素
        parentW: 950,
        parentH: 1386,
        // w: 260,
        // h: 28,
        x: 0,
        y: 0,
      },
      type: "",
      parentW: null,
      parentH: null,
      styleW: 21,
      styleH: 29,
      paperPopVisible: false,
      rules: {
        TemName: [
          { required: true, message: "请输入模板名称", trigger: "blur" },
        ],
        Type: [
          { required: true, message: "请选择是否覆盖模板", trigger: "change" },
        ],
        Amodel: [
          {
            required: true,
            message: "请选择需要覆盖的模板",
            trigger: "change",
          },
        ],
      },
      Amodel: "",
      thead: [
        {
          name: "名称",
          key: "name",
        },
      ],
      headlist: {
        name: "名称",
        key: "name",
      },
      theadmodel: "",
      Amodel2: "",
      datlist: [
        {
          key: "name",
          value: "恩恩额",
        },
      ],
      trueenflase: [
        { keys: "是", value: true },
        { keys: "否", value: false },
      ],
      formobj: {},
      addtablelie: "",
      deletelieList: [],
      tabletabs: "列设置",
      tablerowtabs: "行设置",
      styleText:
        '<style type="text/css" media="print">\n' +
        "  @page { size: portrait; }\n" +
        "</style>",

      columns: [
        { title: "日期", dataIndex: "CreateTime" },
        { title: "金额", dataIndex: "Money" },
        { title: "欠款金额", dataIndex: "Owing" },
      ],
      datalist: [{}, {}, {}, {}, {}],
    };
  },
  components: {
    VueQr,
    VueBarcode,
    CompanySearchSelect,
  },
  created() {},
  methods: {
    textPushInput() {
      this.form.value = this.form.value + "{}";
    },
    confirmkeyType(key) {
      this.keyType = key;
      this.changekeytype();
    },
    getGetQrCodeTmpList(id) {
      this.$http
        .post("/Base/Base_Reconciliation/GetCompanyTemp?CompanyId=" + id, {})
        .then((res) => {
          this.printlist = [];
          setTimeout(() => {
            var obj = JSON.parse(res.Data.Content);
            this.keyType = obj.keyType;
            this.printPages = obj.printPages;
            this.printsize = obj.printsize;
            this.styleH = obj.styleH;
            this.styleW = obj.styleW;
            this.printlist = obj.printlist;
          }, 1);
        });
    },
    changeSearchSelect(a) {
      this.template = [];
      this.Amodel = "";
      this.getGetQrCodeTmpList(a.Id);
    },
    fontSizeChange() {},
    printPageschange() {},
    gethuoqu() {
      //console.log()
      var obj = JSON.parse(localStorage.getItem("模板"));
      this.keyType = obj.keyType;
      this.printsize = obj.printsize;
      this.styleH = obj.styleH;
      this.styleW = obj.styleW;
      this.printlist = obj.printlist;
    },
    TemNameChange(a) {
      //console.log(a)
      var str = this.template.find((item) => {
        return item.Id === a;
      });
      this.printlist = [];
      setTimeout(() => {
        var obj = JSON.parse(str.Content);
        this.keyType = obj.keyType;
        this.printPages = obj.printPages;
        this.printsize = obj.printsize;
        this.styleH = obj.styleH;
        this.styleW = obj.styleW;
        this.printlist = obj.printlist;
        this.$set(this.subform, "TemName", str.TemName);
      }, 1);
    },
    handleOk() {
      var obj = {
        // e==='自定义'?
        printPages: this.printPages, //打印张数
        keyType: this.keyType, //纸张类型
        styleW: this.styleW, //纸张宽度厘米
        styleH: this.styleH, ////纸张高度厘米
        printsize: this.printsize, //打印类型//横向 纵向
        printlist: this.printlist, //打印内容
      };
      //console.log(obj, 'obj')
      this.loading = true;

      this.$http
        .post(`/Base/Base_Accountemp/SaveData`, {
          CompanyId: this.CompanyId,
          TemName: this.subform.TemName,
          Content: JSON.stringify(obj),
          TemType: 1,
        })
        .then((res) => {
          this.loading = false;
          if (res.Success) {
            this.$message.success("保存成功");

            this.modalflag = false;
            this.getGetQrCodeTmpList(this.CompanyId);
          } else {
            this.$message.error("保存失败");
          }
        });
    },
    subbaocun() {
      if (!this.CompanyId) {
        return this.$message.warning("请选择公司后保存");
      }
      if (this.printlist.length === 0) {
        return this.$message.warning("请添加后保存");
      }
      this.handleOk();
      // this.modalflag = true;
      // this.$set(this.subform, "Type", 0);
      // this.$set(this.subform, "Amodel", this.Amodel);
    },
    printsizechange() {
      var h = this.styleH;
      var w = this.styleW;
      //console.log(w, h)

      if (this.printsize === "portrait") {
        if (h > w) {
          this.styleW = w;
          this.styleH = h;
        } else {
          this.styleW = h;
          this.styleH = w;
        }
        this.styleText =
          '<style type="text/css" media="print">\n' +
          "  @page { size: portrait; }\n" +
          "</style>";
      } else {
        if (h > w) {
          this.styleW = h;
          this.styleH = w;
        } else {
          this.styleW = w;
          this.styleH = h;
        }
        this.styleText =
          '<style type="text/css" media="print">\n' +
          "  @page { size: landscape; }\n" +
          "</style>";
      }
      this.printlist = [];
      this.form = {};
      this.Amodel = "";
    },
    Customizeyes() {
      this.keyType = this.Customize.key;
      this.styleW = this.Customize.width;
      this.styleH = this.Customize.height;
      this.paperPopVisible = false;
      this.printlist = [];
    },
    changekeytype() {
      var obj = this.paperTypes.find((item) => {
        return item.key === this.keyType;
      });
      this.styleW = obj.width;
      this.styleH = obj.height;
      this.printsize = "portrait";
      this.styleText =
        '<style type="text/css" media="print">\n' +
        "  @page { size: portrait; }\n" +
        "</style>";
      this.printlist = [];
    },
    getpx(num, wh, str) {
      var dpi = jsgetDPI();
      if (str == "px") {
        if (wh == "w") {
          return (num / 2.54) * dpi[0];
        }
        if (wh == "h") {
          return (num / 2.54) * dpi[1];
        }
      }
      if (str == "cm") {
        if (wh == "w") {
          return (num / dpi[0]) * 2.54;
        }
        if (wh == "h") {
          return (num / dpi[1]) * 2.54;
        }
      }
    },
    del(form) {
      //console.log(form)

      this.printlist.forEach((item) => {
        this.$set(item, "isActive", false);
      });
      setTimeout(() => {
        var index = this.printlist.findIndex((item) => {
          return item.Id === this.form.Id;
        });
        //console.log(index, 'index')

        this.form = {};
        this.printlist.splice(index, 1);
      }, 100);
    },
    formyes(form) {
      var index = this.printlist.findIndex((item) => {
        return item.Id === form.Id;
      });
      this.printlist[index] = form;
      this.printlist[index].isActive = false;
      this.form = {};
      this.formobj = {};
    },
    handleChange(info) {
      //console.log(info)
      if (info.file.status === "done") {
        var urllist = info.fileList
          .filter((x) => x.status == "done")
          .map((x) => x.url || x.response.url);
        this.form.value = urllist[0];
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} 上传失败.`);
      }
    },
    addprint(item) {
      let idx = -1;
      if (item.Keys !== "text") {
        idx = this.printlist.findIndex((im) => im.Keys == item.Keys);
      }
      if (idx !== -1 && item.Keys !== "text") {
        this.clicked(this.printlist[idx]);
        return;
      }
      var arr = [];
      var assobj = {};
      this.printlist.forEach((val) => {
        this.$set(val, "isActive", false);
      });
      this.printlist.forEach((val) => {
        if (val.Keys === item.Keys) {
          arr.push(val);
        }
      });
      this.$set(item, "lineHeight", 28);
      this.$set(item, "fontSize", "18");
      this.$set(item, "bold", "initial");
      this.$set(item, "sort", 0);
      if (item.Keys == "Title") this.$set(item, "textAlign", "center");
      else this.$set(item, "textAlign", "left");
      if (item.type === "4") {
        this.columns.forEach((val, vx) => {
          this.$set(val, "width", 50);
          this.$set(val, "lineHeight", 20);
          this.$set(val, "fontSize", 18);
          this.$set(val, "bold", "initial");
          // this.$set(val, 'lineHeight', 28)
          this.$set(val, "align", "center");
          this.$set(val, "textalign", "center");
          this.$set(val, "textlineHeight", 40);
          this.$set(val, "type", "text");
          this.$set(val, "maxlength", "");
          this.$set(val, "sortIndex", vx + 1);
        });
        // 根据纸张赋值表格行数
        this.$set(item, "columns", this.columns);
        if (item.rowCount) {
          for (let i = 0; i < item.rowCount; i++) {
            arr.push({});
          }
          this.$set(item, "datalist", arr);
        } else {
          if (this.keyType === "A4") {
            this.$set(item, "datalist", [{}, {}, {}, {}, {}, ...this.datalist]);
          } else {
            this.$set(item, "datalist", this.datalist);
          }
        }
        this.$set(item, "w", this.getpx(this.styleW, "w", "px") * 0.8);
        this.$set(item, "h", (item.datalist.length + 1) * 42);
      }
      this.form = {};
      if (arr.length > 0) {
        //console.log('true')
        var assobj2 = { ...item };
        this.$set(assobj2, "Id", item.Id + "-" + arr.length);
        Object.assign(assobj2, { ...this.typeitem }, { ...assobj2 });
        // this.$set(assobj2, 'isActive', false)
        this.printlist = [...this.printlist, assobj2];
      } else {
        Object.assign(assobj, { ...this.typeitem }, { ...item });
        // this.$set(assobj, 'isActive', false)
        this.printlist = [...this.printlist, assobj];
      }
    },
    divcalck() {
      this.printlist.forEach((item) => {
        this.$set(item, "isActive", false);
      });
      this.form = {};
    },
    activated(item, index) {
      //console.log('组件外')
    },
    dragstop(a, item) {
      setTimeout(() => {
        item.w = a.width;
        item.h = a.height;
        item.x = a.left;
        item.y = a.top;
        this.form = { ...item };
      }, 1);
    },
    dragging(a, item) {
      item.w = a.width;
      item.h = a.height;
      item.x = a.left;
      item.y = a.top;
    },
    resizestop(a, item) {
      item.w = a.width;
      item.h = a.height;
      item.x = a.left;
      item.y = a.top;
      this.form = { ...item };
    },
    resizing(a, item) {
      item.w = a.width;
      item.h = a.height;
      item.x = a.left;
      item.y = a.top;
    },
    clicked(item) {
      this.form = {};

      this.printlist.forEach((val) => {
        this.$set(val, "isActive", false);
      });
      this.$set(item, "isActive", true);
    },
    tablecallback(a) {
      //console.log(a)
    },
    refprint() {
      //console.log(this.$refs.print.innerHTML)
      localStorage.setItem("print", this.$refs.print.innerHTML);
    },
    returnclick() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-form-item {
  margin-bottom: 1px;
}
@page {
  size: auto;
  margin: 0mm !important;
}
.barcode {
  width: 100%;
  height: 100%;
}
/deep/ .vue-barcode-element {
  width: 100%;
  height: 100%;
}
</style>
